import React, { useEffect, useState } from 'react';
import { getScheduleById } from '../../services/login.service';
import { useParams } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import moment from 'moment';
import './scheduleView.scss';

const ScheduleView: React.FC = () => {
    const { id } = useParams();
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        const fetchSchedules = async () => {
            if (id === undefined) {
                console.error('No ID provided');
                return;
            }

            try {
                const userId = Number(id);
                const userSchedules = await getScheduleById(userId);
                setSchedules(userSchedules.data);
            } catch (error) {
                console.error('Error fetching user schedules:', error);
            }
        };

        fetchSchedules();
    }, [id,]);

    return (
        <div className='page-container'>
        <Sidebar />
        {schedules.map((schedule: any, index: number) => (
            <div className="record-container" key={index}>
                <div className="record-info">
                    <span className="record-info-label">Team 1:</span>
                    <span className="record-info-value">{schedule.team1}</span>
                </div>
                <div className="record-info">
                    <span className="record-info-label">Time:</span>
                    <span className="record-info-value">{moment(schedule.time).local().format('MMMM D YYYY hh:mm A')}</span>
                </div>
                <div className="record-info">
                    <span className="record-info-label">Location:</span>
                    <span className="record-info-value">{schedule.location}</span>
                </div>
                <table className="manager-table">
                    <tbody>
                        <tr>
                            <td className="manager-label">Manager:</td>
                            <td>{schedule.managerName}</td>
                        </tr>
                        <tr>
                            <td className="manager-label">Manager Birth Date:</td>
                            <td>{moment(schedule.managerBirthDate).format('MMMM D YYYY')}</td>
                        </tr>
                        <tr>
                            <td className="manager-label">Manager Birth Place:</td>
                            <td>{schedule.managerBirthPlace}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="record-info">
                    <span className="record-info-label">Team 2:</span>
                    <span className="record-info-value">{schedule.team2}</span>
                </div>
                <div className="record-info">
                    <span className="record-info-label">Time:</span>
                    <span className="record-info-value">{moment(schedule.time).local().format('MMMM D YYYY hh:mm A')}</span>
                </div>
                <div className="record-info">
                    <span className="record-info-label">Location:</span>
                    <span className="record-info-value">{schedule.location}</span>
                </div>
                <table className="manager-table">
                    <tbody>
                        <tr>
                            <td className="manager-label">Manager:</td>
                            <td>{schedule.managerName1}</td>
                        </tr>
                        <tr>
                            <td className="manager-label">Manager Birth Date:</td>
                            <td>{moment(schedule.managerBirthDate1).format('MMMM D YYYY')}</td>
                        </tr>
                        <tr>
                            <td className="manager-label">Manager Birth Place:</td>
                            <td>{schedule.managerBirthDate1}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        ))}
    </div>
    
    
    );
    
};

export default ScheduleView;