import './config/axios.config';
import './styles/App.scss';
import AppRouting from './routing/Approuting';

function App() {
    return (
        <>
            {/* <BrowserRouter> */}

            <AppRouting />
            {/* </BrowserRouter> */}
        </>
    );
}

export default App;
