import axios from "axios";
import { API_URL_STAGE } from "../core/constants/constants";
axios.defaults.baseURL = API_URL_STAGE




export async function chatbot(payload: any) {
    try {
        const response = await axios.post(`/chatgpt`, payload);
        console.log("response", response);
        if (response && response.status === 201) {
            return response.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e;
    }

}
export async function getUserInfo() {
    try {
        const response = await axios.get(`/users`);
        if (response && response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (error) {
        throw error;
    }
}

export async function getSchedule() {
    try {
        const response = await axios.get(`match/get-schedule-match`);
        if (response && response.status === 200) {
            console.log(response)
            return response.data;
        } else {
            throw response;
        }
    } catch (error) {
        throw error;
    }
}


export async function scrapeData(payload: any) {
    try {
        const response = await axios.post(`/scrpe-data`, payload);
        if (response) {
            console.log("API response", response);
            return response.data;
        } else {
            throw response;
        }
    } catch (error) {
        throw error;
    }
}


export async function getScheduleById(id: number) {
    try {
        console.log("ud",id)
        const response = await axios.get(`match/get-team-schedule/${id}`);
        if (response && response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (error) {
        throw error;
    }
}


