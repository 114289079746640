// need to display all user data in a table format code here

import React, { useEffect, useState } from 'react';
import "./Users.scss";
import { getUserInfo } from '../../services/login.service';
import Sidebar from '../Sidebar/Sidebar';


export const Users = () => {
    const [userData, setUserData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUserInfo();
                setUserData(response.data);
                setOriginalData(response.data);
            } catch (error) {
                throw error;
            }
        };

        fetchData();
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        if (searchValue === '') {
            setUserData(originalData);
        } else {
            const filteredData = originalData.filter((user: any) => user.name.toLowerCase().includes(searchValue.toLowerCase()));
            setUserData(filteredData);
        }
    };

    return (
        <div className='page-container'>
            <Sidebar />
            <div className='content'>
                <div className="table-container">
                    <h1 className="table-title">Users</h1>
                    <div className="search-container">
                        <input type="text" className='search-input' placeholder="Search by name" onChange={handleSearch} />
                    </div>
                    <table className="user-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Date Of Birth</th>
                                <th>Birth Place</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData?.map((user: any, index: number) => (
                                <tr className="user-row" key={index}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.dateOfBirth}</td>
                                    <td>{user.birthPlace}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
