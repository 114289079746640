import React, { useEffect, useState } from 'react';
import "./Schedule.scss"
import { getSchedule } from '../../services/login.service';
import Sidebar from '../Sidebar/Sidebar';
import   moment from 'moment';
import { useNavigate } from 'react-router-dom';





export const Schedule = () => {
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getSchedule();
                setUserData(response.data);
            } catch (error) {
                throw error;
            }
        };
        fetchData();
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        const filteredData = userData.filter((user: any) =>
            user.name && user.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setUserData(filteredData);
    };


    const [showPastGames, setShowPastGames] = useState(false);

    const handleToggleGames = () => {
        setShowPastGames(!showPastGames);
    };

    const filteredUserData = showPastGames ? userData.filter((user: any) => moment(user.details[0].time).isBefore(moment())) : userData.filter((user: any) => moment(user.details[0].time).isAfter(moment()));

    const handleViewSchedule = (user: any) => {
        
        // open the new page with the schedule details

        const url = `/schedule/${user.details[0].id}`;
        
        console.log(url)
        navigate(url);

        };

    return (
        <div className='page-container'>
            <Sidebar />
            <div className='content'>
                <div className="table-container">
                    <h1 className="table-title">Schedule</h1>
                    <div className="game-navigation">
                        <button className={`game-navigation-btn ${showPastGames ? 'active' : ''}`} onClick={handleToggleGames}>
                            <span className="arrow-icon">&#8592;</span> Past Games
                        </button>
                        <button className={`game-navigation-btn ${!showPastGames ? 'active' : ''}`} onClick={handleToggleGames}>
                            Future Games <span className="arrow-icon">&#8594;</span>
                        </button>
                    </div>
                    <div className="search-container">
                        <input type="text" className='search-input' placeholder="Search by name" onChange={handleSearch} />
                    </div>
                    <table className="user-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Teams</th>
                                <th>Time</th>
                                <th>Location</th>
                                <th>Match Count</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(filteredUserData)?.map((user: any, index: number) => (
                                <React.Fragment key={index}>
                                    {user.details.map((detail: any, detailIndex: number) => (
                                        <tr className={`user-row ${detailIndex === 0 ? 'team-row' : ''}`} key={`${index}-${detailIndex}`}>
                                            {detailIndex === 0 && (
                                                <>
                                                   <td rowSpan={user.details.length}>{detail.id}</td>
      <td rowSpan={user.details.length}>{user.teams}</td>
                                                </>
                                            )}
                                            <td>{moment(detail.time).local().format('MMMM D YYYY hh:mm A')}</td>
                                            <td>{detail.location}</td>
                                            {detailIndex === 0 && (
                                                <td rowSpan={user.details.length}>{user.details.length}</td>
                                            )}
                                            <td>
                                                <button className="action-button" onClick={() => handleViewSchedule(user)}>View</button>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};