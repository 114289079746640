import  { useState } from 'react';
import { scrapeData } from '../../services/login.service';
import Sidebar from '../Sidebar/Sidebar';
import './UsersInfo.scss';

interface UserData {
    manager: string;
    managerName: string;
    formattedBirthDate: string;
    placeName: string;
}

const UsersInfo = () => {
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState<UserData[]>([]);

    const handleSearch = async () => {
        try {
            const payload = {
                coachName: searchText
            };

            const response = await scrapeData(payload);
            console.log("response", response);

            setSearchResults([response]); // Set searchResults to an array containing the response data

            setSearchText('');
        } catch (error) {
            console.error('Error searching users:', error);
        }
    };

    console.log("SearchResultText", searchResults);

    return (
        <div className="container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className="search-bar">
                    <input
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <button onClick={handleSearch}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <circle cx="11" cy="11" r="8" />
                            <line x1="21" y1="21" x2="16.65" y2="16.65" />
                        </svg>
                    </button>
                </div>
                <div className="search-results">
                    <table>
                        <thead>
                            <tr>
                                <th>Manager</th>
                                <th>Birth Date</th>
                                <th>Place Name</th>
                                <th>Age</th>
                                <th>Prof Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map((user, index) => (
                                <tr key={index}>
                                    <td><a href={user.manager}>{user.managerName}</a></td>
                                    <td>{user.formattedBirthDate}</td>
                                    <td>{user.placeName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UsersInfo;