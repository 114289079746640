import React, { useState } from 'react';
import './Sidebar.scss';

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleSidebar = () => {
        setCollapsed((prevCollapsed) => !prevCollapsed);
    };

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
            <div className="sidebar-header">
                <h3>Menu</h3>
                <button className="toggle-btn" onClick={toggleSidebar}>
                    {collapsed ? '»' : '«'}
                </button>
            </div>
            <ul className="sidebar-menu">
                <li>
                    <a href="/chat">chat</a>
                </li>
                <li>
                    <a href="/user">User</a>
                </li>
                <li>
                    <a href="/schedule">schedule</a>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
