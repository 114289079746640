import { Routes, Route } from "react-router-dom";
import Chatbot from "../components/Chatbot/Chatbot";
import { Users } from "../components/User/Users";
import UsersInfo from "../components/UserInfo/UsersInfo";
import { Schedule } from "../components/Schedule/Schedule";
import ScheduleView from "../components/Schedule/ScheduleView";




const AppRouting = () => {
    return (

        <Routes>
            <Route path="/chat" element={<Chatbot />} />
            <Route path="/user" element={<Users />} />
            <Route path='/userInfo' element={<UsersInfo />} />
            <Route path ='/schedule' element = {<Schedule />} />
            <Route path="/schedule/:id" element={<ScheduleView />} />
                    </Routes>
    );
};

export default AppRouting;
