import React, { useState } from "react";
import Chats from "../Chats/Chats";
import "./Chatbot.scss";
import ImgSend from '../../assets/images/send.png';

const Chatbot = ({ setChatbotClass }: any) => {
    const [userResponse, setUserResponse] = useState<string>("");
    const [sendUserResponse, setSendUserResponse] = useState<string>("");

    const setNextStep = async (response: string) => {
        setSendUserResponse(response);
        setUserResponse("");
    };

    const optionClick = (e: React.MouseEvent<HTMLElement>) => {
        let option = e.currentTarget.dataset.id;
        if (option) {
            setNextStep(option);
        }
    };

    //  event handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserResponse(e.target.value);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            const cursorPosition = e.currentTarget.selectionStart ?? 0;
            const newValue =
                userResponse.substring(0, cursorPosition) +
                '\n' +
                userResponse.substring(cursorPosition);

            setUserResponse(newValue);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setNextStep(userResponse);
    };

    // const handleCloseChatbotEvent = () => {
    //     const element: any = document.getElementById("chatbot");
    //     if (element) {
    //         element.classList.remove("active");
    //     }
    //     setChatbotClass(false);
    // };

    return (
        <div className="chat-container">
            <div className="header-top">
                Codex
                {/* <div className="closeicon" onClick={handleCloseChatbotEvent}>
          <Closeicon />
        </div> */}
            </div>

            <Chats
                userResponse={userResponse}
                sendUserResponse={sendUserResponse}
                optionClick={optionClick}
            />
            <form onSubmit={(e) => handleSubmit(e)} className="form-container">
                <input
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    value={userResponse}
                ></input>
                <button>
                    <img src={ImgSend} alt="" />
                </button>
            </form>
        </div>
    );
};

export default Chatbot;
